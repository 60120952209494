// Generated by Framer (66beb72)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kwaKu7eUR", "Vb2x6z_vU", "Hox2G36F5"];

const serializationHash = "framer-bJB4q"

const variantClassNames = {Hox2G36F5: "framer-v-1xjfppa", kwaKu7eUR: "framer-v-1y5ipxo", Vb2x6z_vU: "framer-v-uispy4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.75, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Gradient Mobile": "Hox2G36F5", Gradient: "Vb2x6z_vU", White: "kwaKu7eUR"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kwaKu7eUR"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kwaKu7eUR", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y5ipxo", className, classNames)} data-framer-name={"White"} layoutDependency={layoutDependency} layoutId={"kwaKu7eUR"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{background: "linear-gradient(180deg, #FBFBFD 0%, #e795b7 19.87530934478658%, #935893 45.361387919940114%, #224d88 100%)", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, ...style}} variants={{Hox2G36F5: {borderBottomLeftRadius: 30, borderBottomRightRadius: 30}}} {...addPropertyOverrides({Hox2G36F5: {"data-framer-name": "Gradient Mobile"}, Vb2x6z_vU: {"data-framer-name": "Gradient"}}, baseVariant, gestureVariant)}><motion.div className={"framer-h6dcxi"} data-framer-name={"White"} layoutDependency={layoutDependency} layoutId={"uwjr0vUig"} style={{backgroundColor: "rgb(251, 251, 253)", opacity: 1}} variants={{Hox2G36F5: {opacity: 0}, Vb2x6z_vU: {opacity: 0}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bJB4q.framer-1qc2gwx, .framer-bJB4q .framer-1qc2gwx { display: block; }", ".framer-bJB4q.framer-1y5ipxo { height: 1417px; overflow: hidden; position: relative; width: 1200px; will-change: var(--framer-will-change-override, transform); }", ".framer-bJB4q .framer-h6dcxi { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-bJB4q.framer-v-1xjfppa .framer-h6dcxi { bottom: 312px; left: 194px; right: -194px; top: -312px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1417
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Vb2x6z_vU":{"layout":["fixed","fixed"]},"Hox2G36F5":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJIIh83COP: React.ComponentType<Props> = withCSS(Component, css, "framer-bJB4q") as typeof Component;
export default FramerJIIh83COP;

FramerJIIh83COP.displayName = "BG";

FramerJIIh83COP.defaultProps = {height: 1417, width: 1200};

addPropertyControls(FramerJIIh83COP, {variant: {options: ["kwaKu7eUR", "Vb2x6z_vU", "Hox2G36F5"], optionTitles: ["White", "Gradient", "Gradient Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerJIIh83COP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})